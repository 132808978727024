.scroll-top {
    position: fixed;
    bottom: 4em;
    right: 2em;
    background-color: #4bffa5;
    padding: 10px;
    border-radius: 100%;
  }
  
  @media (max-width: 900px) {
    .scroll-top {
      display: none;
    }
  }