.contact {
    display: flex;
    align-content: flex-start;
    justify-content: space-evenly;
    align-items: flex-start;
    max-width: 100%;
    padding: 50px 100px 60px;
    flex-wrap: wrap;
    flex-direction: row;
}

.contact a {
    text-align: center;
}

.contact a:hover {
    font-style: bold;
    text-decoration: none;
    color: aquamarine;
}

.contactText{
    max-width: 100%;
    padding: 10px;
    text-align: center;
    margin: auto 10px;
}


.contactForm{
    max-width: 35vw;
    padding: 20px;
}

.contactList{
    display: flex;
}

.rows {
    display: flex;
    width: 35%;
    padding-right: 10px;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.rows h6{
    color: grey
}

.rows h3{
    color: navy;
    text-shadow: 1px 2px aquamarine;
    margin-bottom: 20px;
}

#more{
    color: navy;
    text-shadow: 1px 2px aquamarine;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.contacLinks {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    text-align: center;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
}

.contacLinks h1{
    margin-top: 0px;
}

#submit {
    font-weight: 600;
}

.cv {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 260px;
    align-items: center;
    font-weight: 300px;
}

.gifImage {
    margin-left: 50px;
    width: auto;
    height: 200px;
    padding: 0px 70px;
}

.dowload {
    display: flex;
    flex-direction: column;
    width: 35%;
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    margin-top: -39px;
}

#buttonCv {
    size: 400px;
    color: navy;
    margin-left: 0px;
    background-color: white;
    border: solid 2px aquamarine;
}

@media (max-width: 900px) {
    .rows {
        display: flex;
        width: 85%;
        padding: 10px 0px 0px;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        margin: 0px 0px 30px;
    }
    .contactForm{
    width: 30%;
    padding: 0px;
    }

    .dowload {
        margin-top: 20px;
        margin-bottom: 40px;
        width: 100%;
    }
    .gifImage {
        margin: 5px;
        width: auto;
        height: 300px;
        margin-right: 10px;
    }

    #buttonCv {
        margin-top: 10%;
        margin-left: 0;
        size: 500px;
    }
}

@media (max-width: 600px) {
    .contact {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        padding: 10px 10px 20px;
        align-content: space-around;
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
    .contacLinks {
        text-align: center;
        width: 100%;
    }
    .contacLinks a {
        text-align: center;
        overflow: visible;
    }
    .rows {
        display: flex;
        max-width: 85%;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }

    .contactText{
        max-width: 100%;
        padding: 2px;
        text-align: center;
        margin: 20px auto 1px;
    }
    .gifImage {
        width: auto;
        height: 300px;
    }

    .dowload {
        margin-top: 20px;
        margin-bottom: 40px;
        width: 100%;
    }
    .cv a{
        width: 100%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        font-weight: 300px;
    }
}