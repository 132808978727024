
.about {
    display: flex;
    align-content: center;
    align-items: center;
    background-color: #4bffa5;
    max-width: 100%;
    padding: 50px 120px 70px;
}

.about p {
    margin-top: 40px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 16px;
}

.about span {
    font-family: Arial, Helvetica, sans-serif;
    font-weight:bolder;
    font-size: larger;
    margin: 0px;
}

.aboutContainer {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
}

.aboutText {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    justify-content: center;
    max-width: 70%;
    padding: 0 20px;
}

.aboutText h3 {
    text-align: left;
    margin: 0px;
    font-family: "poppins" sans-serif;
    font-weight: 400;
    color: navy;
}

.aboutText h1 {    
    text-align: left;
    margin: 10px 0px;
    font-family: "poppins" sans-serif;
    font-size: 50px;
    color: navy;
    text-shadow: -6px 6px 0 white;
    
}

.aboutText p {
    font-family: "poppins" sans-serif;
    margin: 10px 0;
    color: navy;
    font-weight: 300;
}

.avatar {
    width: 100%;
    padding: 0 20px;
    height: auto;
    object-fit: cover;
    border-radius: 2px;
}

.aboutButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.click {
    margin-left: 45vw;
    font-size: 40px;
    position: absolute;
}

.blink {
    font-size: 16px;
    animation: blinker 1s linear infinite;
    color: navy;
    font-family: sans-serif;
}
@keyframes blinker {
    70% {
        opacity: 0;
    }
}

@media (max-width: 900px) {

#first {
margin-top: 50px;
}

.about {
padding: 10px 10px 20px;
}

.aboutContainer {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column-reverse;
    align-content: center;
    justify-content: flex-start; 
}

article {
    display: flex;
    flex-direction: column;
   }
    .aboutText {
      max-width: 80%;
      padding-left: 10px;
      font-size: smaller;
    }
    .button {
    overflow: inherit;
    width: 95%; 
    }
    .aboutButtons { 
    margin: 50px auto 2px;
    display: flex;
    align-items: stretch;
    max-width: 95%;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    }
    .avatar {
    max-width: 80%;
    text-align: center;
    padding: 0px 0px
    }
    .click {
        margin: 100px  80px auto 390px;
        font-size: 40px;
    }
}

@media (max-width: 500px) {
 .about span {
    font-size: 45px;
    }
}

