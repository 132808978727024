.containerF {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 25px;
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;
    width: 92%;
    justify-content: space-between;
    border: 3px solid navy;
    background-color: #5f5f5e4e;
    border-radius: 4px;
}

@media (max-width: 300px) {
    .containerF {
        max-width: 200px;
    }

}

input,
label,
select,
button,
textarea
{
	margin:0;
	border:0;
	padding:0;
	display:inline-block;
	vertical-align:middle;
	white-space:normal;
	background:none;
	line-height:1;
	
}

input,
textarea:focus
{
	outline:0;
    line-height: normal;
}

.form_container  {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 300px;
    max-width: 300px;
}

.contact_form {
    display: flex;
    flex-direction: column;
    margin: 25px;
    font-family: 'Poppins', sans-serif;
}

.form_title {
    color: navy;
    margin-top: 30px;
}

.form_label {
    margin: 5px;
    align-items: left;
    float: right;
	width: 55%;
}

#text_input {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
	color: #122293;
}

input::placeholder,
textarea::placeholder {
    font-family: 'Poppins', sans-serif;
    color: navy;
}

.submit {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 8px 40px;
}

input[type='text'], [type='email'], textarea {
	background: none;
  border: none;
	border-bottom: solid 2px #122293;
	color: #6f76ad;
	font-size: 1.000em;
    font-weight: 400;
    letter-spacing: 1px;
	margin: 0em 0 1.875em 0;
	padding: 0 0 0.875em 0;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
