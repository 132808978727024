#skills {
    background-color: white;
    background-size: cover;
}

.skills__list {
    max-width: 550px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  ul.skills__list {
    padding: 0;
}
  
  .skills__list-item {
    margin: 0.5em;
  }

.skills__list-item:hover {
  transform: translateY(-7px);

  box-shadow: 5px 0 14px rgba(33, 33, 33, 0.2); 
  font-weight: 600;
}

#hard:hover {
  background-color: aquamarine;
}

#soft:hover {
  color: navy;
}

.infin {
  margin: 80px 0px 60px 0px;
}

@media (max-width: 900px) {
  #skills {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}
.skills__list {
  max-width: 380px;
  width: 100%;
  padding: 0 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
}
