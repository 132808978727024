   #spamCircle {
    overflow-x: hidden 
   }

   .circle {
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 310px;
     height: 310px;
     border-radius: 50%;
     margin: 60px;
   }
   
   .logo {
     position: absolute;
     width: 235px;
     height: 235px;
     border-radius: 50%;
     background-image: url("../../assets/images/squaregif.gif");
     background-size: cover;
     background-position: center;
   }


   @keyframes rotateText
   {
     0%
     {
      transform: rotate(360deg); 
     }
     100% 
     {
      transform: rotate(0deg); 
     }
   }
   
   .text {
     position: absolute;
     width: 100%;
     height: 100%;
     color: navy;
     font-family: arial;
     animation: rotateText 10s linear infinite;
     overflow-x: hidden 
   }
   
   .text span {
     position: absolute;
     left: 50%;
     font-size: 22px;
     transform-origin: 0 155px;
     color: navy;
     z-index: 99;
   }

  