body {
    margin: 0;
}

.toggle-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: space-around;
    align-items: stretch;
}

.toggle-wrapper img[src] {
    width: 100%;
    border-radius: 20px;
}

.toggle-wrapper img:hover {
    border-radius: 20px 0px;
    cursor: pointer;
    
}
@media (min-width: 1400px) {
    .toggle-wrapper img[src] {
        border-radius: 50%;
    }
}
@media (max-width: 1000px) {
    .toggle-wrapper img[src] {
        border-radius: 0px;
    }
}
    @media (max-width: 600px) {
    .toggle-wrapper {
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        align-content: center;
        cursor: pointer;
    }
    
  }
