.projects__grid {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
    grid-gap: 2em;
  }

  #projects {
    background-color: white;
    background-size: cover;
  }

.projects__grid h3 {
  color: navy;
}

@media (max-width: 900px) {
    #projects {
      margin-right: 0px;  }
  }
