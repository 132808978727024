
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

ul {
  margin: 0;
}

.container {
  max-width: 1920px;
  margin: 22px auto;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  align-content: center;
  height: 70px;
  width: 80%;
  justify-content: space-between;
  border: 3px solid navy;
  background-color: #5f5f5e4e;
  border-radius: 4px;
}

.navbar {
  margin: 0px;
  width: 100%;
  position: inherit;
  text-align: center;
  background-color: #4bffa5
}


.menu-icon {
  display: none;
}

.nav-elements a {
  animation: slidein 10s linear;
}

.nav-elements a:hover {
  color: #4bffa5;
  box-shadow: -2px -2px #574c4c;
  list-style-type: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  text-align: center;
}


.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 600;
  color: navy;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: relative;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
}

@media (max-width: 600px) {
  .container {
    justify-content: flex-start;
    width: 100%;
    margin: 0px auto;
    background-color: #9e9e9e91;
  }

  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .menu-icon {
    display: block;
    cursor: pointer;
  }
  .nav-elements.active {
    width: 270px;
    height: 230px;
    /* position: absolute; */
  }

  .nav-elements {
    position: absolute;
    right: 0.5px;
    top: 0.5px;
    background-color: #5f5f5e4e;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    border: 3px solid navy;
  }

  .nav-elements.false {
    border: none;
  }
  
  .nav-elements.active {
    width: 270px;
    height: 250px;
    background-color: #9e9e9e91;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 60px;
    margin-left: 20px;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 20px;
  }

  div.hamburger-react {
    color: navy;
  }
}